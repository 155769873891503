#signuppage {
    padding: 30px 0;

    #Signup {
        @include Contentbox;
        margin: 30px 60px;

        @include mq ('phone-and-tablet') {
            margin: 0;
        }

        .container {

            .signup-section-grid,
            .Mobile-section-wrapper {
                box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
                padding: 0;

                //left section scss
                .Left-section-wrapper {
                    padding-left: 0;

                    @include mq ('phone-and-tablet') {
                        text-align: center;
                    }

                    .left-section-inner {
                        background-color: $login-left-bg;
                        height: 100%;
                        position: relative;
                        @include mq ('phone-and-tablet'){
                            padding-bottom: 10px;
                        }

                        .skipark-logo {
                            text-align: center;
                            width: 52%;

                            @include mq ('phone-and-tablet') {
                                margin: auto;
                            }

                            svg {
                                margin-top: 21px;
                            }
                        }

                        .left-section-text {
                            padding: 0px 30px;
                            margin-bottom: 30px;

                            h3 {
                                font-size: 18px;
                                color: $text-black;
                                font-weight: $font-semi-bold;
                                line-height: 30px;
                            }
                        }

                        .signup-illustration {
                            text-align: center;
                            padding: 0 5px;
                            width:70%;
                            margin: auto;
                            margin-bottom: 30px;
                            font-family: 'chanakyaregular' !important;
                            @include mq ('phone-and-tablet') {
                                position: relative;
                            }

                            svg {
                                width: 100%;
                                height: 100%;
                                display: block;
                            }
                        }
                        .Quotebox{
                            border:3px solid $color-pink;
                            border-radius: 12px 32px;
                            padding: 20px 14px;
                            margin: 40px;
                            position: relative;
                            margin-bottom: 35px;
                            .quotetext{
                               font-size: 11px;
                               font-weight: $font-regular;
                               line-height: 20px;
                               color:$quote-text;
                            }
                            .openquote{
                                position: absolute;
                                top:-40px;
                                width:15%;
                                left:30px;
                                height: 60px;
                                background-color: $login-left-bg;
                                @include mq ('phone') {
                                    top:-30px;
                                    height: 40px;
                                    width:20%;
                                }
                                svg{
                                    width:100%;
                                    height: 120px;
                                    @include mq ('phone') {
                                        height: 80px;
                                    }
                                }
                            }
                            .closequote{
                                position: absolute;
                                bottom:-25px;
                                width: 15%;
                                right: 30px;
                                height: 60px;
                                background-color: $login-left-bg;
                                @include mq ('phone') {
                                    bottom:-40px;
                                    width:20%;
                                }
                                svg{
                                    width:100%;
                                    height: 120px;
                                    @include mq ('phone') {
                                        height: 80px;
                                    }
                                }
                            }
                        }
                    }
                }

                //right section scss
                .right-section {
                    padding: 20px 36px;

                    .Sign-up-header {
                        padding: 54px 0 27px 0;

                        @include mq ('phone-and-tablet') {
                            text-align: center;
                        }

                        h2 {
                            font-size: 22px;
                            color: $color-primary-green;
                            letter-spacing: 0px;
                            word-spacing: -2px;
                            font-weight: $font-semi-bold;
                        }
                    }

                    .fieldrow,
                    .email {
                        margin-bottom: 28px;
                        position: relative;

                        .ui.icon.input>i.icon {
                            font-family: icons !important;
                        }

                        .validation_msg {
                            position: absolute;
                            width: 50%;

                            @include mq('phone') {
                                width: 100%;
                            }
                        }

                        .password-validation,
                        .Email-validation {
                            width: 100%;
                            margin-top: 3px;

                            .content {
                                margin: 0 !important;

                                @include mq('phone-and-tablet') {
                                    margin-left: 5px !important;
                                }
                            }
                        }

                        .firstname-validation,
                        .Contact-validation {
                            bottom: -20px;
                            left: -5px;
                        }

                        .Lastname-validation{
                            bottom: -20px;
                            right: 5px;
                            
                            @include mq('phone-and-tablet') {
                                left: -5px;
                                right:unset;
                            }
                        }
                        .Company-validation{
                            right:5px;
                            bottom: -20px;
                            
                            @include mq('phone-and-tablet') {
                                left: -5px;
                                right:unset;
                            }
                        }

                        .Email-validation {
                            bottom: -20px;
                            left: 0px;
                        }

                    }

                    .fieldrow.has_error {
                        margin-bottom: 32px;
                    }

                    .fieldrow.password_has_error {
                        margin-bottom: 50px;

                        @include mq('phone') {
                            margin-bottom: 80px;
                        }
                    }

                    .create-password {
                        position: relative;

                        .eye {
                            position: absolute;
                            top: 28px;
                            right: 10px;
                            font-family: icons !important;
                            cursor: pointer;
                        }
                    }

                    .termscheckbox {
                        margin-bottom: 14px;
                        align-items: baseline;
                        @include mq ('phone') {
                            align-items: end;
                        }
                        @include mq ('tablet') {
                            margin: 5px 0;
                            align-items: center;
                        }

                        label {
                            font-size: 12px;
                            font-weight: $font-regular;
                            color: $text-black;
                        }
                        .Terms-service-link {
                            font-size: 12px !important;
                            margin-left: 4px;
                            @include link;
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                font-size: 11px !important;
                            } 
                            @include mq ('phone-and-tablet') {
                                margin-left: 0px;
                            }
                        }
                        .terms-of-services{
                            width:89%;
                            margin-left:5px;
                            word-break:break-word;
                            line-height:14px;
                        }
                    }

                    .create-account-wrapper {
                        align-items: center;
                        padding-bottom: 10px;
                        .column{
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                width: 100% !important;
                                text-align: center;
                                margin-bottom: 20px;
                            }
                        }
                        .Createaccountbtn {
                            text-align: right;
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                text-align: center;
                            }

                            @include mq ('phone-and-tablet') {
                                text-align: center;
                                margin-bottom: 20px;
                            }

                            button {
                                @include PrimaryButton;
                            }
                        }

                        .or-text-grid-child {
                            padding-right: 0px;

                            .or-text {
                                font-size: 12px;
                                color: $color-primary-green;
                                text-align: center;
                                font-weight: $font-semi-bold;

                                @include mq ('phone-and-tablet') {
                                    margin-bottom: 20px;
                                }
                            }
                        }

                        .Already-a-member {
                            @include mq ('phone-and-tablet') {
                                text-align: center;

                            }

                            p {
                                font-size: 12px;
                                font-weight: $font-regular;
                                font-style: italic;
                                color: $text-black;
                            }

                            .login-link {
                                font-size: 12px !important;
                                padding-left: 3px;
                                @include link;
                            }
                        }
                    }

                    .powered-by-text {
                        text-align: center;
                        border-top: 1px solid $text-black;
                        padding: 8px;
                        margin-top: 36px;

                        .poweredBy-wrapper {
                            align-items: center;

                            .powered-text {
                                text-align: right;

                                h3 {
                                    font-size: 12px;
                                    color: $text-black;
                                    font-weight: $font-medium;
                                }
                            }

                            .skiparklogo {
                                text-align: left;
                                margin-left: -21px;

                                @include mq('phone') {
                                    margin-left: 0px;
                                }

                                svg {
                                    width: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}