// All General mixins

// Button mixin starts
@mixin PrimaryButton {
    color: $white;
    font-size: 14px;
    background-color: $color-primary-green;
    padding: 12px 31px;
    font-weight: $font-semi-bold;
    width: 200px;
    box-shadow: 0px 5px 14px 1px rgba(0,0,0,0.1);
    border-radius: 2px;
}

// Button mixin ends

//Header mixin starts
// used in forgot and reset password page
@mixin Header {
    font-size: 22px;
    font-weight: $font-semi-bold;
    color:$color-primary-green;
}
//Header mixin ends

//Main Content box mixin starts
@mixin Contentbox{
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: calc(100vh);
}
//Main Content box mixin ends

//Mixin for all green text links starts
@mixin link{
    font-size: 16px;
    color: $color-primary-green;
    font-weight: $font-semi-bold;
    font-style: normal;
    cursor: pointer;
}
//Mixin for all green text links ends

//Mixin for Header
//used in: payment page
@mixin h2{
    font-size: 22px;
    color: $color-primary-green;
    letter-spacing: 0px;
    word-spacing: -2px;
    font-weight: $font-semi-bold;
}