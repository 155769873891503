#Thankyou-page {
    padding: 30px 0;
    text-align: center;

    #Thankyou {
        @include Contentbox;
        margin-top: 145px;
        @include mq('phone-and-tablet'){
            margin-top: 0px;
        }
        @media only screen and (min-width:991px) and (max-width: 1024px){
            margin-top: 10%;
            min-height: calc(75vh);
        } 

        .container {
            position:relative;
            .mill-skitech-logo{
                top: -179px;
                position: absolute;
                left: -20px;
                z-index: 10;
                height: auto;
                max-width: 25%;
                width: 100%;
                @include mq('phone'){
                    position:relative;
                    top: 0px;
                    left:0px;
                    max-width: 85%;
                    margin: auto;
                }
                @include mq ('tablet'){
                    position:relative;
                    top: 0px;
                    left:0px;
                    max-width: 50%;
                    margin: auto;
                }
                @media only screen and (min-width:991px) and (max-width: 1024px){
                    position:relative;
                    top: 0px;
                    left:0px;
                    max-width: 50%;
                    margin: auto;
                } 
            }
            .Thankyou-inner-content{
                position:relative;
                max-width: 1022px;
                min-height: 310px;
                margin: auto;
                box-shadow: 0px 5px 14px 1px rgba(0,0,0,0.1);
                padding: 46px 40px;
                margin-bottom: 60px;
                @include mq('phone'){
                    padding: 46px 17px;
                }
                .box-content{
                    font-size: 20px;
                    font-weight: $font-regular;
                    color:$text-black;
                    line-height: 34px;
                    margin-bottom: 15px;
                    max-width: 62%;
                    text-align: left;
                    @include mq('phone-and-tablet'){
                        max-width: 100%;
                        text-align: center;
                        font-size: 18px;
                    }
                }
                .prashant-shah{
                    color:$color-primary-green;
                    font-size: 20px;
                    font-weight: $font-semi-bold;
                    text-align: left;
                    @include mq('phone-and-tablet'){
                        max-width: 100%;
                        text-align: center;
                        font-size: 18px;
                    }
                }
                .prashantimage{
                    position: absolute;
                    top: -114px;
                    right: 11px;
                    width: 33%;
                    @include mq('phone-and-tablet'){
                        position: relative;
                        top:0px;
                        right:0px;
                        width:70%;
                    }
                }
            }
            .email-link-wrapper {
                margin-bottom: 32px;
                max-width: 800px;
                margin: auto;
                @include mq('phone'){
                    margin-bottom: 40px;
                }
                .email-link {
                    p {
                        font-size: 20px;
                        font-weight: $font-medium;
                        color: $text-black;
                        line-height: 30px;
                        @include mq('phone'){
                            font-size: 18px;
                        }
                    }
                    .email-link {
                        @include link;
                        font-size: 20px;
                        cursor: initial;
                        @include mq('phone'){
                            font-size: 18px;
                        }
                    }
                }
            }
            .Desktopbuttongroup{
                display: block;
                margin:20px 0;
                @include mq('phone-and-tablet'){
                    display: none;
                }
                .Logoutbtn{
                    text-align: end;
                    button {
                        @include PrimaryButton;
                    }
                }
                .Paymentbtn{
                    text-align: left;
                    button{
                        @include PrimaryButton;
                        padding: 12px 0px;
                        background-color: $button-bg;
                    }
                }
            }
            .Mobilebuttongroup{
                display:block;
                margin-bottom: 40px;
                margin-top: 20px;
                @include mq('lap-and-up'){
                    display: none;
                }
                .Logoutbtn{
                    text-align: center;
                    margin-bottom: 20px;
                    button {
                        @include PrimaryButton;
                    }
                }
                .Paymentbtn{
                    text-align: center;
                    button{
                        @include PrimaryButton;
                        padding: 12px 0px;
                        background-color: $button-bg;
                    }
                }
            }
            .social-media{
                .social{
                    font-size: 18px;
                    font-weight: $font-medium;
                    color: $text-black;
                    padding-right: 18px;
                    @include mq('phone-and-tablet'){
                        margin-bottom: 10px;
                    }
                }
                .social-icons{
                    svg{
                        margin-right: 15px;
                        cursor:pointer;
                        @include mq('phone'){
                            margin-right: 10px;
                        }
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}