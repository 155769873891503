/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
*, :after, :before{
    box-sizing: border-box;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video , button{
  margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: $font-family !important;
    vertical-align: baseline;
}
/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}
/**
Correct inline-block display not defined in IE 6/7/8/9 and Firefox 3. */
audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}
/**
Prevent modern browsers from displaying audio without controls.
Remove excess height in iOS 5 devices. */
audio:not([controls]) {
    display: none;
    height: 0;
}
/**
Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
Known issue: no IE 6 support. */
[hidden] {
    display: none;
}
/**
Correct text resizing oddly in IE 6/7 when body font-size is set using
em units.
Prevent iOS text size adjust after orientation change, without disabling
user zoom. */
html {
    font-size: 100%; /* 1 /
    -webkit-text-size-adjust: 100%; / 2 /
    -ms-text-size-adjust: 100%; / 2 */
}
/**
Address outline inconsistency between Chrome and other browsers. */
a:focus {
    outline: none;
}
/**
Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}
/**
Remove border when inside a element in IE 6/7/8/9 and Firefox 3.
Improve image quality when scaled in IE 7.
*/
img {
    border: 0; /* 1 /
    -ms-interpolation-mode: bicubic; / 2 */
}
/**
Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11. */
figure {
    margin: 0;
}
/**
Correct margin displayed oddly in IE 6/7. */
form {
    margin: 0;
}
/**
Define consistent border, margin, and padding. */
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
/**
Correct color not being inherited in IE 6/7/8/9.
Correct text not wrapping in Firefox 3.
Correct alignment displayed oddly in IE 6/7.
*/
legend {
    border: 0; /* 1 /
    padding: 0;
    white-space: normal; / 2 /
    *margin-left: -7px; / 3 */
}
/**
Correct font size not being inherited in all browsers.
Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
and Chrome.
Improve appearance and consistency in all browsers.
*/
button,
input,
select,
textarea {
    font-size: 100%; /* 1 /
    margin: 0; / 2 /
    vertical-align: baseline; / 3 /
    *vertical-align: middle; / 3 */
}
/**
Address Firefox 3+ setting line-height on input using !important in
the UA stylesheet. */
button,
input {
    line-height: normal;
}
/**
Address inconsistent text-transform inheritance for button and select.
All other form control elements do not inherit text-transform values.
Correct button style inheritance in Chrome, Safari 5+, and IE 6+.
Correct select style inheritance in Firefox 4+ and Opera. */
button,
select {
    text-transform: none;
}
/**
Avoid the WebKit bug in Android 4.0.* where (2) destroys native audio
and video controls.
Correct inability to style clickable input types in iOS.
Improve usability and consistency of cursor style between image-type
input and others.
Remove inner spacing in IE 7 without affecting normal text inputs.
Known issue: inner spacing remains in IE 6. */
button,
html input[type="button"], /* 1 /
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; / 2 /
    cursor: pointer; / 3 /
    *overflow: visible;  / 4 */

/**
Re-set default cursor for disabled elements. */
button[disabled],
html input[disabled] {
    cursor: default;
}
/**
Address box sizing set to content-box in IE 8/9.
Remove excess padding in IE 8/9.
Remove excess padding in IE 7.
Known issue: excess padding remains in IE 6. */
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 /
    padding: 0; / 2 /
    *height: 13px; / 3 /
    *width: 13px; / 3 */
}
/**
Address appearance set to searchfield in Safari 5 and Chrome.
Address box-sizing set to border-box in Safari 5 and Chrome
(include -moz to future-proof). */
input[type="search"] {
    -webkit-appearance: textfield; /* 1 /
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; / 2 */
    box-sizing: content-box;
}
/**
Remove inner padding and search cancel button in Safari 5 and Chrome
on OS X. */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
/**
Remove inner padding and border in Firefox 3+. */
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
/**
Remove default vertical scrollbar in IE 6/7/8/9.
Improve readability and alignment in all browsers.
*/
textarea {
    overflow: auto; /* 1 /
    vertical-align: top; / 2 */
}
/**
Remove most spacing between table cells. */
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html,
button,
input,
select,
textarea {
    color: #222;
}
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}
::selection {
    background: #b3d4fc;
    text-shadow: none;
}
img {
    vertical-align: middle;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
textarea {
    resize: vertical;
}
.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
