#Loginpage {
    padding: 30px 0;

    #Login {
        @include Contentbox;
        margin: 30px 60px;

        @include mq ('phone-and-tablet') {
            margin: 0;
            display: block;
        }

        .container {
            .login-section-grid {
                align-items: center;
            }

            .login-section-grid,
            .Mobile-section-wrapper {
                box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
                padding: 0;

                //left section scss
                .Left-section-wrapper {
                    padding-left: 0;
                    height: 100%;

                    @include mq ('phone-and-tablet') {
                        text-align: center;
                    }

                    .left-section-inner {
                        background-color: $login-left-bg;
                        height: 100%;
                        position: relative;

                        .skipark-logo {
                            text-align: center;
                            width: 52%;

                            @include mq ('phone-and-tablet') {
                                margin: auto;
                            }

                            svg {
                                margin-top: 22px;
                            }
                        }

                        .left-section-text {
                            padding: 0px 30px;
                            margin-top: -9px;
                            margin-bottom: 130px;

                            @include mq ('phone-and-tablet') {
                                margin-bottom: 70px;
                            }

                            h3 {
                                font-size: 20px;
                                color: $text-black;
                                font-weight: $font-semi-bold;
                                line-height: 30px;

                                @include mq ('phone-and-tablet') {
                                    font-size: 14px;
                                }
                            }

                            h4 {
                                font-size: 18px;
                                font-weight: $font-medium;
                                color: $text-black;
                                line-height: 25px;
                                margin-top: -11px;

                                @include mq ('phone-and-tablet') {
                                    font-size: 14px;
                                }
                            }
                        }

                        .login-illustration {
                            text-align: center;
                            // position: absolute;
                            // bottom: 0;
                            width: 100%;
                            padding: 0 5px;

                            @include mq ('phone-and-tablet') {
                                position: relative;
                            }

                            svg {
                                height: 100%;
                                width: 100%;
                                display: block;
                            }
                        }
                    }
                }

                //right section scss
                .right-section {
                    padding: 20px 57px;

                    @include mq ('phone-and-tablet') {
                        padding: 20px 36px;
                    }

                    .Login-header {
                        padding: 0 0 27px 0;

                        @include mq ('phone-and-tablet') {
                            text-align: center;
                            padding-top: 56px;
                        }

                        h2 {
                            font-size: 22px;
                            color: $color-primary-green;
                            letter-spacing: 0px;
                            word-spacing: -2px;
                            font-weight: $font-semi-bold;
                        }
                    }

                    .fieldrow,
                    .email {
                        margin-bottom: 34px;

                        .ui.icon.input>i.icon {
                            font-family: icons !important;
                        }

                        .password {
                            position: relative;

                            .eye {
                                position: absolute;
                                top: 28px;
                                right: 10px;
                                font-family: icons !important;
                                cursor: pointer;
                            }
                        }
                    }

                    .login-account-wrapper {
                        align-items: center;
                        margin-bottom: 28px;
                        .login{
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                width:100% !important;
                            } 
                        }
                        .Loginaccountbtn {
                            text-align: right;
                            @include mq ('phone-and-tablet') {
                                text-align: center;
                                margin-bottom: 20px;
                            }
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                text-align: center;
                                margin-bottom: 20px;
                            }

                            button {
                                @include PrimaryButton;
                            }
                        }

                        .or-text-grid-child {
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                width:100% !important;
                                margin-bottom: 20px;
                            } 
                            .or-text {
                                font-size: 12px;
                                color: $color-primary-green;
                                text-align: center;
                                font-weight: $font-semi-bold;
                                padding-left:35px;

                                @media only screen and (max-width: 1024px){
                                    margin-bottom: 20px;
                                    padding:0;
                                }
                                
                            }
                        }
                        .notamember{
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                width:100% !important;
                            } 
                        }
                        .Not-a-member {
                            @include mq ('phone-and-tablet') {
                                text-align: center;
    
                            }
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                text-align: center;
                            }  
                            p {
                                font-size: 12px;
                                font-weight: $font-regular;
                                font-style: italic;
                                color: $text-black;
                            }
                            .signup-link {
                                font-size: 12px !important;
                                padding-left: 3px;
                                @include link;
                            }
                        }
                    }

                    .Forgotpassword-link {
                        @include mq ('phone-and-tablet') {
                            text-align: center;

                        }
                        @media only screen and (min-width:991px) and (max-width: 1024px){
                            text-align: center;
                        } 

                        .forgotpassword {
                            font-size: 12px;
                            color: $color-primary-green;
                            font-weight: $font-semi-bold;
                            font-style: normal;
                            cursor: pointer;
                        }
                    }

                    .powered-by-text {
                        text-align: center;
                        border-top: 1px solid $text-black;
                        padding: 8px;
                        margin-top: 26px;

                        .poweredBy-wrapper {
                            align-items: center;

                            .powered-text {
                                text-align: right;

                                h3 {
                                    font-size: 12px;
                                    color: $text-black;
                                    font-weight: $font-medium;
                                }
                            }

                            .skiparklogo {
                                text-align: left;
                                margin-left: -21px;
                                @include mq('phone') {
                                    margin-left: 0px;
                                }
                                svg {
                                    width: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}