#Paymentpage {
    padding: 30px 0;

    #Payment {
        @include Contentbox;
        margin: 16px 60px;

        @include mq ('phone-and-tablet') {
            margin: 0;
        }

        .container {
            .Payment-section-grid {
                align-items: center;
            }

            .Payment-section-grid,
            .Mobile-section-wrapper {
                box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
                padding: 0;

                //left section scss
                .Left-section-wrapper {
                    padding-left: 0;
                    height: 100%;

                    @include mq ('phone-and-tablet') {
                        text-align: center;
                    }

                    .left-section-inner {
                        background-color: $login-left-bg;
                        height: 100%;
                        position: relative;

                        .skipark-logo {
                            text-align: center;
                            width: 52%;

                            @include mq ('phone-and-tablet') {
                                margin: auto;
                            }

                            svg {
                                margin-top: 14px;
                            }
                        }

                        .left-section-text {
                            padding: 0px 30px;
                            margin-top: -9px;
                            margin-bottom: 110px;

                            @include mq ('phone-and-tablet') {
                                margin-bottom: 80px;
                            }

                            h2 {
                                @include h2;
                                margin-bottom: 23px;

                                @include mq ('phone') {
                                    font-size: 18px;
                                    margin: 10px 0 23px 0;
                                }
                            }
                            .Parent-wrapper{
                                @include mq ('phone-and-tablet') {
                                    margin-bottom: 10px;
                                }
                                .associatemember {
                                    @include mq ('phone-and-tablet') {
                                        width: 100% !important;
                                        margin-bottom: 10px;
                                    }
    
                                    h3 {
                                        font-size: 16px;
                                        color: $text-black;
                                        font-weight: $font-semi-bold;
    
                                        @include mq ('phone-and-tablet') {
                                            font-size: 14px;
                                        }
                                    }
                                }
    
                                .price-wrapper {
                                    padding-left: 6px;
                                    @include mq ('phone-and-tablet') {
                                        width: 100% !important;
                                    }
    
                                    .price {
                                        font-size: 16px;
                                        font-weight: $font-medium;
                                        color: $text-black;
                                    }
                                }
                            }

                            .access-wrapper {
                                padding-right: 14px;
                                @include mq ('phone-and-tablet') {
                                    width: 100% !important;
                                    margin-bottom: 10px;
                                }
                                @media only screen and (min-width:991px) and (max-width: 1024px){
                                    width: 100% !important;
                                    margin-bottom: 20px;
                                }

                                h4 {
                                    font-size: 16px;
                                    font-weight: $font-regular;
                                    color: $text-black;
                                    line-height: 25px;
                                    margin-top: -11px;

                                    @include mq ('phone-and-tablet') {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .numberpicker-wrapper {
                                padding-right: 0px;
                                padding-left: 0px;
                                margin: 0 -12px auto auto;
                                @media only screen and (max-width: 1024px){
                                    margin:0 auto;
                                    width: 100% !important;

                                }
                                @media only screen and (min-width:991px) and (max-width: 1024px){
                                    text-align: center;
                                }
                                .ui.button{
                                    padding: 3px !important;
                                    border-radius: 10px;
                                    height: 17px;
                                    margin-right: 5px;
                                    margin-left: 5px;
                                    min-height: 0px;
                                    line-height: 0;
                                    font-size: 15px;
                                    font-weight: $font-regular;
                                    background: transparent;
                                    border: 1px solid $text-black;
                                }
                                .ui.input>input{
                                    width: 40px;
                                    padding: 3px;
                                    text-align: center;
                                    font-family: $font-family;
                                    background: transparent;
                                    border: 1px solid $text-black;
                                    border-radius: 0px !important;
                                    font-size: 12px;
                                }
                            }
                        }

                        .Payment-illustration {
                            text-align: center;
                            width: 100%;
                            padding: 0 5px;

                            @include mq ('phone-and-tablet') {
                                position: relative;
                            }

                            svg {
                                width: 100%;
                                display: block;

                                @include mq ('phone-and-tablet') {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                //right section scss
                .right-section {
                    padding: 20px 57px;

                    @include mq ('phone-and-tablet') {
                        padding: 20px 36px;
                    }

                    .Payment-header {
                        padding: 35px 0 27px 0;

                        @include mq ('phone-and-tablet') {
                            text-align: center;
                            padding-top: 56px;
                        }

                        h2 {
                            @include h2;
                        }
                    }

                    .fieldrow {
                        margin-bottom: 34px;

                        .cardinfo {
                            margin: 0;

                            @include mq ('phone-and-tablet') {
                                margin-bottom: 34px;
                            }
                        }

                        .card-info-wrapper {
                            padding: 0 8px;

                            @include mq ('phone-and-tablet') {
                                padding: 0;
                            }

                            .date,
                            .cvv {
                                padding: 0;
                            }
                        }
                    }

                    .payment-wrapper {
                        align-items: center;
                        margin-bottom: 28px;
                        .column{
                            @include mq ('phone-and-tablet') {
                                width:100% !important;
                            }
                            @media only screen and (min-width:991px) and (max-width: 1024px){
                                width: 100% !important;
                                text-align: center;
                                margin-bottom: 20px;
                            }

                        }

                        @include mq ('phone-and-tablet') {
                            text-align: center;
                        }

                        .paymentbtn {
                            @include mq ('phone-and-tablet') {
                                text-align: center;
                                margin-bottom: 20px;
                            }

                            button {
                                @include PrimaryButton;
                                padding: 12px 0px;
                                width: 130px;

                                @include mq ('phone') {
                                    font-size: 12px;
                                }
                            }
                        }

                        .or-text {
                            font-size: 12px;
                            color: $color-primary-green;
                            text-align: center;
                            font-weight: $font-semi-bold;

                            @include mq ('phone-and-tablet') {
                                margin-bottom: 20px;
                            }
                        }

                        .generate-invoice-wrapper {
                            padding: 0 15px 0 0;

                            @include mq ('phone-and-tablet') {
                                padding: 0;
                            }

                            .Generate-invoicebtn {
                                @include mq ('phone-and-tablet') {
                                    text-align: center;
                                    margin-bottom: 20px;
                                }

                                button {
                                    @include PrimaryButton;
                                    padding: 12px 0px;
                                    width: 246px;
                                    background-color: $button-bg;

                                    @include mq ('phone') {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}