/* Document
	 ========================================================================== */

/**
 * Colors used in this website
 */


//Brand color
$white: #ffffff;
$color-primary-green:#87A87A;
$text-black:#6b6b6b;
$para-black:#b5b5b7;
$color-pink:#BF9F9F;
$quote-text:#292929;
//Background
$login-left-bg:#fffafa;
$button-bg:#b1b1b1;
$message-box-bg:#f2f0f0;

//placeholder
$placeholder: #c2c2c6;

//validation-color
$Validation-red: #E31717;

// Dynamic variable to link with API
