#Forgot-password-page {
    padding: 30px 0;
    text-align: center;

    #ForgotPassword {
        @include Contentbox;
        margin-top: 60px;
        @include mq('phone'){
            margin-top: 0px;
        }

        .container {
            .forgot-password-header {
                padding-bottom: 26px;
                h2 {
                    @include Header;
                    @include mq('phone'){
                        font-size: 21px;
                    }
                }
            }
            .forgot-password-sub-para{
                max-width: 70%;
                margin:auto;
                margin-bottom: 29px;
                @include mq('phone'){
                    max-width: 100%;
                }
                p{
                    font-size: 16px;
                    color:$para-black;
                    line-height: 20px;
                    font-weight: $font-regular;
                }
            }
            .fieldrow
            {
                margin-bottom: 31px;
                text-align: left;
                padding: 0 67px;
                position:relative;
                @include mq('phone'){
                    padding: 0px;
                }
                .validation_msg {
                    position: absolute;
                    width: 50%;

                    @include mq('phone') {
                        width: auto;
                    }
                }
                .Email-validation{
                    bottom: -20px;
                    left: 0;
                    padding-left: 67px;
                    .ui.message {
                        .list:not(.ui) {
                            li{
                            margin:0px !important;
                            }
                        }
                    }
                    @include mq('phone') {
                        top:56px;
                        padding-left: 0px;
                    }
                }
            }

            .Submitbtn{
                margin-bottom: 30px;
                button {
                    @include PrimaryButton;
                }
            }
            .ui.icon.message{
                margin: 20px 20px 25px 0;
                display: flex !important;
                .circle{
                    margin-top: 4px;
                }
                .content{
                    .content-inner{
                        font-size: 16px;
                        font-weight: $font-medium;
                        color:$text-black;
                        line-height: 25px;
                        .span-text{
                            @include link;
                        }
                    }
                }
            }
            .go-back-link-wrapper {
                margin-bottom: 57px;
                @include mq('phone'){
                    margin-bottom: 0px;
                }
                .go-back-link {
                    p {
                        font-size: 16px;
                        font-weight: $font-medium;
                        color: $text-black;
                    }

                    .login-link {
                        padding-left: 3px;
                        @include link;
                    }
                }

            }
            .ForgotpasswordSvg{
                svg{
                    width:100%;
                }
            }
        }
    }
}