#Invoice-page {
    padding: 30px 0;
    text-align: center;
    background-image: url('/img/InvoiceBackground.png');
    background-repeat: no-repeat;
    background-size: cover;

    #Invoice {
        @include Contentbox;
        margin: 76px 0;
        @include mq('phone-and-tablet') {
            margin: 0px;
        }

        .container {
            .Invoice-wrapper {
                position: relative;
                max-width: 567px;
                box-shadow: 0px 5px 14px 1px rgba(0,0,0,0.1);
                padding: 20px 70px;
                background-color: $white;
                @include mq('phone-and-tablet') {
                    padding: 20px;
                }
                .Invoice-header {
                    margin-bottom: 17px;
                    padding-top: 5px;
                    h2 {
                        @include Header;
                        font-size: 18px;
                        line-height: 22px;
                        padding: 3px 20px 10px;
                        @include mq('phone-and-tablet') {
                            font-size: 15px;
                            padding: 0 0 30px 0;
                            max-width: 360px;
                            margin: auto;
                        }
                    }
                }

                .fieldrow {
                    margin-bottom: 30px;
                    text-align: left;
                    position: relative;

                    @include mq('phone-and-tablet') {
                        padding: 0px;
                    }
                    .Email,.State,.Country{
                        @include mq('phone-and-tablet') {
                           margin-bottom: 0px;
                        }
                    }
                    .Name,.Town,.PostalCode{
                        @include mq('phone') {
                            margin-bottom: 30px;
                        }
                    }
                    .validation_msg {
                        position: absolute;
                        width: 50%;

                        @include mq('phone') {
                            width: auto;
                        }
                    }
                    .firstname-validation,
                    .town-validation,
                    .postalcode-validation
                    {
                        bottom: -20px;
                        left: -5px;
                        @include mq('phone') {
                            top:56px;
                        }
                    }
                    .Email-validation,
                    .state-validation,
                    .country-validation
                    {
                        bottom: -20px;
                        right: 5px;
                        @include mq('phone') {
                            left:-5px;
                        }
                    }
                    .Company-validation,
                    .Addr1-validation,
                    .Addr2-validation{
                        bottom: -20px;
                        left: -12px;
                    }

                }
                .fieldrow.has_error {
                    margin-bottom: 32px;
                }
                .Button-wrapper{
                    padding-bottom:20px;
                    .row{
                        align-items: baseline;
                        padding-bottom: 0px;
                    }
                    .Button-wrapper-inner{
                        @include mq('phone-and-tablet') {
                            width:100% !important;
                        }
                        .Submitbtn {
                            margin-bottom: 20px;
                            button {
                                @include PrimaryButton;
                            }
                        }
                        .go-back-link-wrapper {
                            @include mq('phone-and-tablet') {
                                margin-bottom: 0px;
                            }
        
                            .go-back-link {
                                p {
                                    font-size: 12px;
                                    font-weight: $font-medium;
                                    color: $text-black;
                                }
        
                                .Payment-link {
                                    @include link;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                .ui.icon.message{
                    position: absolute;
                    max-width: 410px;
                    background-color:$message-box-bg;
                    top: -75px;
                    right: -263px;
                    border-radius: 2px;
                    @include mq('phone-and-tablet') {
                        position: relative;
                        max-width: 540px;
                        top:0;
                        right:0;
                    }
                    .content{
                        .content-inner{
                            font-size: 16px;
                            font-weight: $font-medium;
                            line-height: 24px;
                            color:$text-black;
                        }
                    }
                    .icon:not(.close){
                        font-family: icons !important;
                    }
                }
            }
        }
    }
}