#Reset-password-page {
    padding: 30px 0;
    text-align: center;

    #ResetPassword {
        @include Contentbox;
        margin-top: 60px;
        display: block;
        @include mq('phone'){
            margin-top: 0px;
        }
        .ResetPassword-wrapper{
            max-width: 560px;
        }
        .container {
            .Reset-password-header {
                padding-bottom: 27px;
                h2 {
                    @include Header;
                    @include mq('phone'){
                        font-size: 21px;
                    }
                }
            }
            .fieldrow
            {
                margin-bottom: 31px;
                text-align: left;
                padding: 0 27px;
                position: relative;
                @include mq('phone'){
                    padding: 0px;
                }
                .validation_msg {
                    position: absolute;
                    padding: 0 40px;
                    @include mq('phone') {
                        width: auto;
                        padding:0px;
                    }
                }
                .password-validation,
                .confirmpassword-validation{
                    top: 56px;
                    left: 0px;
                    @include mq('phone') {
                        left: -10px;
                    }
                }

            }
            .fieldrow.has_error {
                margin-bottom: 32px;
            }
            .fieldrow.password_has_error {
                margin-bottom: 50px;

                @include mq('phone') {
                    margin-bottom: 60px;
                }
            }

            .Submitbtn{
                margin-bottom: 30px;
                button {
                    @include PrimaryButton;
                }
            }
            .go-back-link-wrapper {
                margin-bottom: 107px;
                @include mq('phone'){
                    margin-bottom: 0px;
                }
                .go-back-link {
                    p {
                        font-size: 16px;
                        font-weight: $font-medium;
                        color: $text-black;
                    }
                    .login-link {
                        padding-left: 3px;
                        @include link;
                    }
                }

            }
            .ResetpasswordSvg{
                svg{
                    width:100%;
                }
            }
        }
    }
}