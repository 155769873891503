// General scss 

// checkbox scss starts
// used in: signup page, login page
.ui.checkbox {

  input:checked~.box:after,
  input:checked~label:after {
    content: '\e800';
    background: $color-primary-green;
    color: $white !important;
    border-radius: 4px;
  }

  .box:before,
  label:before {
    border: 1px solid $text-black !important;
  }
}

// checkbox scss ends

// form field scss starts
// used in: signup page, login page, ForgotPassword , ResetPassword
.ui.form {
  .disabled.field,
  .field.disabled>label,
  .disabled.input {
      opacity: 1 !important;
  }
  .field {
    @media screen and (max-width:767px) {
      margin-bottom: 20px;
    }
    .disabled {
      :disabled{
        background-color:#e6e6e6;
      }
    }

    label {
      font-size: 12px;
      font-weight: $font-medium;
      color: $text-black;
    }
  }

  .field,
  .fields {
    .ui.input {
      input {
        border: 1px solid $text-black;
        font-style: normal;
        font-family: $font-family !important;
        font-size: 14px;
        font-weight: 400;
        color:$text-black;
      }
    }
  }
}

//form field scss ends

//form field placeholder scss starts
// used in: signup page, login page, ForgotPassword , ResetPassword
input::placeholder {
  font-style: italic;
  font-size: 14px;
  font-weight: $font-regular;
  color: $placeholder !important;
  font-family: $font-family !important;
}

//form field placeholder scss ends

//Message box scss starts
// used in: Forgot password page
.ui.icon.message {
  max-width: 540px;
  background-color: $white;
  box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
  align-items: flex-start;

  .icon:not(.close) {
    font-size: 20px;
    color: $color-primary-green;
    font-family: icons !important;
  }

  .content {
    text-align: left;
  }

  p {
    margin: 0em;
  }
}

//Message box scss ends
// Container scss starts
.container {
  margin: auto;
  padding: 0 15px;
  max-width: 1026px;
}

// Container scss ends

//flexbox scss starts
.flex_view {
  >div[class^="col"] {
    padding-left: 0;
    padding-right: 0;
  }

  @media(min-width:768px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &.left {
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    &.right {
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }

    &.center {
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    &.space-between {
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &.space-around {
      -webkit-justify-content: space-around;
      -ms-flex-pack: justify;
      justify-content: space-around;
    }

    &.middle {
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &.end {
      -webkit-align-items: end;
      -ms-flex-align: end;
      align-items: end;
    }

    &.start {
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    &.reverse {
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }

    &.space {
      margin: 0 -15px;

      >div[class^="col"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

  }
}

.flex_view_xs {

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  &.left {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  &.right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  &.center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &.space-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &.space-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
    justify-content: space-around;
  }

  &.middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &.end {

    -webkit-align-items: end;
    -ms-flex-align: end;
    align-items: end;
  }

  &.start {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &.reverse {
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  &.space {
    margin: 0 -15px;

    >div[class^="col"] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  >div[class^="col"] {
    padding-left: 0;
    padding-right: 0;
  }
}

//flexbox scss ends

//error message scss starts
.ui.attached.error.message,
.ui.error.message {
  background-color: transparent !important;
  box-shadow: 0 0 0 0px #e0b4b4 inset, 0 0 0 0 transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 12px;
  font-weight: $font-regular;
  font-style: italic;
  color: $Validation-red;

  li:before {
    display: none;
  }
}
.ui.form {
  .field.error{
    label{
      color:$text-black;
      &:after{
        color:$Validation-red;
      }
    }
    input[type=text],input[type=password]{
      background-color: $white;
      border:1px solid $Validation-red;
    }
  }
}

//error message scss ends
//segment scss starts
//Used in reset password screen
.ui.segment{
  border: none !important;
  padding:0 !important;
  margin:0 !important;
}
//segment scss ends

// Stripe payment field scss starts
.StripeElement{
  border: 1px solid $text-black;
    font-style: normal;
    font-family: "Montserrat" !important;
    font-size: 14px;
    font-weight: 400;
    color: $text-black;
    -webkit-appearance: value;
    -moz-appearance: value;
    appearance: value;
    padding: 10px;
    background: $white;
    border-radius: 5px;
    margin-bottom: 34px;
}
.ElementsApp {
  .InputElement{
    font-size: 13px !important;
    font-family: $font-family !important;
  }
}
// Stripe payment field scss ends
// loader scss starts
.loader-scroll{
  overflow:hidden;
}
.ui.loader{
  position:fixed;
}
// loader scss ends

//hide key icon in safari
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}