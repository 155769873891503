#TermsOfService{
    .terms-wrapper{
        margin: 30px 60px;
        box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
        padding: 20px;
        @include mq ('phone-and-tablet') {
            margin: 30px 0;
        }
        .terms-header{
            margin-bottom: 50px;
            text-align: center;
            h2{
                font-size: 30px;
                font-weight: $font-bold;
                color:$color-primary-green;
                @include mq ('phone') {
                    font-size: 20px;
                }
            }
        }
        .Section-wrapper{
            margin-bottom: 30px;
            .header{
                font-size: 20px;
                font-weight: $font-medium;
                color:$color-primary-green;
                line-height: 25px;
                letter-spacing: 1px;
                margin-bottom: 20px;
                @include mq ('phone') {
                    font-size: 15px;
                }
            }
            .Section-content{
                p{
                    font-size: 15px;
                    font-weight: $font-regular;
                    color:$text-black;
                    line-height: 25px;
                    margin-bottom: 20px;
                    word-spacing: 1px;
                    text-align: justify;
                    a{
                        color:$color-primary-green;
                        font-weight: $font-medium;
                    }
                }
            }
        }
    }
}